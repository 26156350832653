import { Button } from '@mui/material';
import * as Papa from 'papaparse';
import { PropsWithChildren } from 'react';

type FieldTypes = number | string | boolean;

interface Props<T extends Record<string, FieldTypes> = Record<string, FieldTypes>> {
  data: T[];
  fields: { key: keyof T; header: string }[];
  fileName: string;
}

export const DownloadCsv = ({ data, fields, fileName, children }: Required<PropsWithChildren<Props>>) => {
  const csv = Papa.unparse(data, { columns: fields.map((f) => f.header) });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8,' });

  const objUrl = URL.createObjectURL(blob);
  return (
    <Button href={objUrl} download={fileName}>
      {children}
    </Button>
  );
};
