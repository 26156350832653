import { isObject, isString } from 'lodash';
import { v4 } from 'uuid';
import { useApi, useClientForExternalCalls } from '../../lib';

const parseImageUrlResponse = (data: unknown): { uploadUrl: string; dataUrl: string } => {
  if (!isObject(data)) {
    throw new Error('data is not an object');
  }
  const dataObj: Record<string, unknown> = { ...data };
  if (!isString(dataObj.uploadUrl)) {
    throw new Error('uploadUrl is invalid');
  }
  if (!isString(dataObj.dataUrl)) {
    throw new Error('dataUrl is invalid');
  }
  return {
    uploadUrl: dataObj.uploadUrl,
    dataUrl: dataObj.dataUrl
  };
};

interface Data {
  newImageFile?: BodyInit;
  imageUrl?: string;
}

export const useUploadPublicImage = () => {
  const api = useApi();
  const externalClient = useClientForExternalCalls();

  return async (data: Data) => {
    let responseJson: { uploadUrl: string; dataUrl: string } | undefined;
    let imageUrl = String(data.imageUrl);
    if (data.newImageFile) {
      const response = await api.get(`admin/wines/${v4()}/image_upload_url`, {
        headers: { 'X-Grapes-Api-Version': '2' }
      });
      if (!response) {
        throw new Error('response can not be undefined');
      }
      responseJson = parseImageUrlResponse(await response.json());
      await externalClient.put(responseJson.uploadUrl, { body: data.newImageFile });
      imageUrl = responseJson.dataUrl;
    }
    return imageUrl;
  };
};
