import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { UseControllerProps, useController } from 'react-hook-form';

interface InputProps
  extends Omit<OutlinedTextFieldProps, 'onChange' | 'inputRef' | 'value' | 'error' | 'helperText' | 'variant'> {
  name: string;
  defaultValue?: string | number;
  rules?: UseControllerProps['rules'];
}

export const FormInput = ({ name, label, defaultValue, rules, ...rest }: InputProps): JSX.Element => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    defaultValue,
    rules
  });

  const hasError = Boolean(error);
  const errorMessage: string = error ? String(error.message) : '';

  return (
    <TextField
      {...rest}
      error={hasError}
      label={String(label) === 'undefined' || label === '' ? name : label}
      variant="outlined"
      helperText={errorMessage}
      inputRef={ref}
      {...fieldProps}
    />
  );
};
